/* ==== Google font ==== */

@import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,400italic,700|Open+Sans:300,400,600,700');
$fa-font-path: "../fonts";
@import "node_modules/font-awesome/scss/font-awesome.scss";
@import url('/css/overwrite.css');
@import url('/css/animate.css');
@import url('/css/flexslider.css');

/* -----------------------------------
	Helpers
----------------------------------- */

.no-margins {
    margin: 0!important;
}

.no-paddings {
    padding: 0!important;
}

.m_t_40{
    margin-top: 40px;
}

.full-width{
    width: 100%;
}
/* -----------------------------------
1. General
----------------------------------- */

html {
    position: relative;
    height: 100%;
}

body {
    font-family: 'Open Sans', Arial, sans-serif;
    height: 100%;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.6em;
    color: #656565;
    display: flex;
    flex-flow: column;
}

a:active {
    outline: 0;
}

.clear {
    clear: both;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: 700;
    line-height: 1.1em;
    color: #333;
    margin-bottom: 20px;
}


/* -----------------------------------
2. layout
----------------------------------- */

.container {
    padding: 0 20px 0 20px;
    position: relative;
}

#wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
}

.wrapper {
    min-height: 100%;
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
}

.row, .row-fluid {
    margin-bottom: 30px;
}

.row .row, .row-fluid .row-fluid {
    margin-bottom: 30px;
}

.row.nomargin, .row-fluid.nomargin {
    margin-bottom: 0;
}


/* -----------------------------------
3. Responsive media
----------------------------------- */

.video-container, .map-container, .embed-container {
    position: relative;
    margin: 0 0 15px 0;
    padding-bottom: 51%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border: none;
}

.embed-container iframe, .embed-container object, .embed-container embed, .video-container iframe, .map-container iframe, .map-container object, .map-container embed, .video-container object, .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

iframe {
    border: none;
}

img.img-polaroid {
    margin: 0 0 20px 0;
}

.img-box {
    max-width: 100%;
}


/* -----------------------------------
4. Header
----------------------------------- */


/* --- header -- */

header .navbar {
    margin-bottom: 0;
}

.navbar-default {
    border: none;
}

.navbar-brand {
    color: #222;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: -1px;
    margin-top: 30px;
    padding: 0 0 0 15px;
}

header .navbar-collapse ul.navbar-nav {
    float: right;
    margin-right: 0;
}

header .navbar-default {
    background-color: #fff;
}

header .nav li a:hover, header .nav li a:focus, header .nav li.active a, header .nav li.active a:hover, header .nav li a.dropdown-toggle:hover, header .nav li a.dropdown-toggle:focus, header .nav li.active ul.dropdown-menu li a:hover, header .nav li.active ul.dropdown-menu li.active a {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

header .navbar-default .navbar-nav>.open>a, header .navbar-default .navbar-nav>.open>a:hover, header .navbar-default .navbar-nav>.open>a:focus {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

header .navbar {
    min-height: 100px;
}

header .navbar-nav>li {
    padding-bottom: 30px;
    padding-top: 30px;
}

header .navbar-nav>li>a {
    padding-bottom: 6px;
    padding-top: 5px;
    margin-left: 2px;
    line-height: 30px;
    font-weight: 700;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.dropdown-menu li a:hover {
    color: #fff !important;
}

header .nav .caret {
    border-bottom-color: #f5f5f5;
    border-top-color: #f5f5f5;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
    background-color: #fff;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
    background-color: #fff;
}

.dropdown-menu {
    box-shadow: none;
    border-radius: 0;
    border: none;
}

.dropdown-menu li:last-child {
    padding-bottom: 0 !important;
    margin-bottom: 0;
}

header .nav li .dropdown-menu {
    padding: 0;
}

header .nav li .dropdown-menu li a {
    line-height: 28px;
    padding: 3px 12px;
}


/* --- menu --- */

header .navigation {
    float: right;
}

header ul.nav li {
    border: none;
    margin: 0;
}

header ul.nav li a {
    font-size: 12px;
    border: none;
    font-weight: 700;
    text-transform: uppercase;
}

header ul.nav li ul li a {
    font-size: 12px;
    border: none;
    font-weight: 300;
    text-transform: uppercase;
}

.navbar .nav>li>a {
    color: #111;
    text-shadow: none;
}

.navbar .nav a:hover {
    background: none;
}

.navbar .nav>.active>a, .navbar .nav>.active>a:hover {
    background: none;
    font-weight: 700;
}

.navbar .nav>.active>a:active, .navbar .nav>.active>a:focus {
    background: none;
    outline: 0;
    font-weight: 700;
}

.navbar .nav li .dropdown-menu {
    z-index: 2000;
}

header ul.nav li ul {
    margin-top: 1px;
}

header ul.nav li ul li ul {
    margin: 1px 0 0 1px;
}

.dropdown-menu .dropdown i {
    position: absolute;
    right: 0;
    margin-top: 3px;
    padding-left: 20px;
}

.navbar .nav>li>.dropdown-menu:before {
    display: inline-block;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-bottom-color: none;
    content: none;
}

ul.nav li.dropdown a {
    z-index: 1000;
    display: block;
}

select.selectmenu {
    display: none;
}


/* -----------------------------------
5. Section: Featured
----------------------------------- */

#featured {
    width: 100%;
    background: #fcfcfc;
    position: relative;
    margin: 0;
    padding: 0;
}


/* -----------------------------------
6. Sliders
----------------------------------- */


/* --- flexslider --- */

#featured .flexslider {
    padding: 0;
    margin: 50px 0 30px;
    background: #fff;
    position: relative;
    zoom: 1;
}

.flex-caption {
    background: none;
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000, endColorstr=#4C000000);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#4C000000, endColorstr=#4C000000);
    zoom: 1;
}

.flex-caption {
    bottom: 35px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    margin: 0;
    padding: 25px 25px 25px 30px;
    position: absolute;
    right: 45px;
    width: 295px;
}

.flex-caption h3 {
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.flex-caption p {
    margin: 0 0 15px;
}


/* -----------------------------------
7. Section: call action
----------------------------------- */

section.callaction {
    background: #f9f9f9;
    padding: 50px 0 0 0;
}


/* -----------------------------------
8. Section: Content
----------------------------------- */

#content {
    position: relative;
    background: #fff;
    padding: 50px 0 40px 0;
}

#content img {
    max-width: 100%;
    height: auto;
}


/* --- Call to action --- */

.cta-text {
    text-align: center;
    margin-top: 10px;
}

.big-cta .cta {
    margin-top: 10px;
}


/* --- box --- */

.marketing-boxes .box {
    height: 250px;
}

.box {
    width: 100%;
}

.box-inner {
    height: 100%;
}

.box-gray {
    background: #f8f8f8;
    padding: 20px 20px 30px;
}

.box-gray h4, .box-gray i {
    margin-bottom: 20px;
}

.box-bottom {
    padding: 20px 0;
    text-align: center;
}

.box-bottom a {
    color: #fff;
    font-weight: 700;
}

.box-bottom a:hover {
    color: #eee;
    text-decoration: none;
}


/* -----------------------------------
9. Section: Bottom
----------------------------------- */

#bottom {
    background: #fcfcfc;
    padding: 50px 0 0;
}


/* twitter */

#twitter-wrapper {
    text-align: center;
    width: 70%;
    margin: 0 auto;
}

#twitter em {
    font-style: normal;
    font-size: 13px;
}

#twitter em.twitterTime a {
    font-weight: 600;
}

#twitter ul {
    padding: 0;
    list-style: none;
}

#twitter ul li {
    font-size: 20px;
    line-height: 1.6em;
    font-weight: 300;
    margin-bottom: 20px;
    position: relative;
    word-break: break-word;
}


/* -----------------------------------
10. Inner - Section: page headline
----------------------------------- */

#inner-headline {
    background: #252525;
    position: relative;
    margin: 0;
    padding: 0;
    color: #fefefe;
}

#inner-headline .inner-heading h2 {
    color: #fff;
    margin: 20px 0 0 0;
}


/* --- breadcrumbs --- */

#inner-headline ul.breadcrumb {
    margin: 30px 0 0;
    float: left;
}

#inner-headline ul.breadcrumb li {
    margin-bottom: 0;
    padding-bottom: 0;
}

#inner-headline ul.breadcrumb li {
    font-size: 13px;
    color: #fff;
}

#inner-headline ul.breadcrumb li i {
    color: #dedede;
}

#inner-headline ul.breadcrumb li a {
    color: #fff;
}

ul.breadcrumb li a:hover {
    text-decoration: none;
}


/* -----------------------------------
11. Forms
----------------------------------- */


/* --- contact form  ---- */

form#contactform input[type="text"] {
    width: 100%;
    border: 1px solid #f5f5f5;
    min-height: 40px;
    padding-left: 20px;
    font-size: 13px;
    padding-right: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

form#contactform textarea {
    border: 1px solid #f5f5f5;
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
    font-size: 13px;
    padding-right: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

form#contactform .validation {
    font-size: 11px;
}

.validation {
    color: red;
    display: none;
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 13px;
}

#sendmessage {
    color: green;
    border: 1px solid green;
    display: none;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#errormessage {
    color: red;
    display: none;
    border: 1px solid red;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#sendmessage.show, #errormessage.show, .show {
    display: block;
}


/* --- comment form ---- */

form#commentform input[type="text"] {
    width: 100%;
    min-height: 40px;
    padding-left: 20px;
    font-size: 13px;
    padding-right: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
}

form#commentform textarea {
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
    font-size: 13px;
    padding-right: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 2px 2px 2px 2px;
    -moz-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
}


/* --- search form --- */

.search {
    float: right;
    margin: 35px 0 0;
    padding-bottom: 0;
}

#inner-headline form.input-append {
    margin: 0;
    padding: 0;
}


/* -----------------------------------
12. Portfolio
----------------------------------- */

.work-nav #filters {
    margin: 0;
    padding: 0;
    list-style: none;
}

.work-nav #filters li {
    margin: 0 10px 30px 0;
    padding: 0;
    float: left;
}

.work-nav #filters li a {
    color: #7F8289;
    font-size: 16px;
    display: block;
}

.work-nav #filters li a:hover {}

.work-nav #filters li a.selected {
    color: #DE5E60;
}

#thumbs {
    margin: 0;
    padding: 0;
}

#thumbs li {
    list-style-type: none;
}

.item-thumbs {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    cursor: pointer;
}

.item-thumbs a+img {
    width: 100%;
}

.item-thumbs .hover-wrap {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 450ms ease-out 0s;
    -moz-transition: all 450ms ease-out 0s;
    -o-transition: all 450ms ease-out 0s;
    transition: all 450ms ease-out 0s;
    -webkit-transform: rotateY(180deg) scale(0.5, 0.5);
    -moz-transform: rotateY(180deg) scale(0.5, 0.5);
    -ms-transform: rotateY(180deg) scale(0.5, 0.5);
    -o-transform: rotateY(180deg) scale(0.5, 0.5);
    transform: rotateY(180deg) scale(0.5, 0.5);
}

.item-thumbs:hover .hover-wrap, .item-thumbs.active .hover-wrap {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: rotateY(0deg) scale(1, 1);
    -moz-transform: rotateY(0deg) scale(1, 1);
    -ms-transform: rotateY(0deg) scale(1, 1);
    -o-transform: rotateY(0deg) scale(1, 1);
    transform: rotateY(0deg) scale(1, 1);
}

.item-thumbs .hover-wrap .overlay-img {
    position: absolute;
    width: 50%;
    height: 100%;
    opacity: 0.80;
    filter: alpha(opacity=80);
    background: #000;
}

.item-thumbs .hover-wrap .overlay-img-thumb {
    position: absolute;
    border-radius: 60px;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -16px;
    color: #fff;
    font-size: 32px;
    line-height: 1em;
    opacity: 1;
    filter: alpha(opacity=100);
}


/* --- Portolio filter --- */

ul.portfolio-categ {
    margin: 10px 0 30px 0;
    padding: 0;
    float: left;
    list-style: none;
}

ul.portfolio-categ li {
    margin: 0;
    padding: 0 20px 0 0;
    float: left;
    list-style: none;
    font-size: 13px;
    font-weight: 600;
}

ul.portfolio-categ li a {
    display: block;
    padding: 35x 0 35x 0;
    color: #353535;
}

ul.portfolio-categ li.active a:hover, ul.portfolio-categ li a:hover, ul.portfolio-categ li a:focus, ul.portfolio-categ li a:active {
    text-decoration: none;
    outline: 0;
}


/* --- portfolio detail --- */

.top-wrapper {
    margin-bottom: 20px;
}


/* -----------------------------------
13. Elements
----------------------------------- */


/* --- blockquote --- */

blockquote {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Noto Serif', serif;
    font-style: italic;
    padding-left: 0;
    color: #a2a2a2;
    line-height: 1.6em;
    border: none;
}

blockquote cite {
    display: block;
    font-size: 12px;
    color: #666;
    margin-top: 10px;
}

blockquote cite:before {
    content: "\2014 \0020";
}

blockquote cite a, blockquote cite a:visited, blockquote cite a:visited {
    color: #555;
}


/* --- pullquotes --- */

.pullquote-left {
    display: block;
    color: #a2a2a2;
    font-family: 'Noto Serif', serif;
    font-size: 14px;
    line-height: 1.6em;
    padding-left: 20px;
}

.pullquote-right {
    display: block;
    color: #a2a2a2;
    font-family: 'Noto Serif', serif;
    font-size: 14px;
    line-height: 1.6em;
    padding-right: 20px;
}


/* --- button --- */

.btn-theme {
    color: #fff;
}

.btn-theme:hover {
    color: #eee;
}

.btn:focus,.btn:active:focus,.btn.active:focus,
.btn.focus,.btn:active.focus,.btn.active.focus {
    outline: none;
}

/* --- list style --- */

ul.general {
    list-style: none;
    margin-left: 0;
}

ul.link-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.link-list li {
    margin: 0;
    padding: 2px 0 2px 0;
    list-style: none;
}

footer ul.link-list li a {
    color: #fff;
}

footer ul.link-list li a:hover {
    color: #eee;
}


/* --- Heading style --- */

h4.heading {
    font-weight: 700;
}

.heading {
    margin-bottom: 30px;
}

.heading {
    position: relative;
}

.widgetheading {
    width: 100%;
    padding: 0;
}

#bottom .widgetheading {
    position: relative;
    border-bottom: #e6e6e6 1px solid;
    padding-bottom: 9px;
}

aside .widgetheading {
    position: relative;
    border-bottom: #e9e9e9 1px solid;
    padding-bottom: 9px;
}

footer .widgetheading {
    position: relative;
}

footer .widget .social-network {
    position: relative;
}

#bottom .widget .widgetheading span, aside .widget .widgetheading span, footer .widget .widgetheading span {
    position: absolute;
    width: 60px;
    height: 1px;
    bottom: -1px;
    right: 0;
}


/* --- Map --- */

.map {
    position: relative;
    margin-top: -50px;
    margin-bottom: 40px;
}


/* google map */

#google-map {
    position: relative;
    height: 450px;
}


/* --- our team --- */

ul.team-detail {
    margin: -10px 0 0 0;
    padding: 0;
    list-style: none;
}

ul.team-detail li {
    border-bottom: 1px dotted #e9e9e9;
    margin: 0 0 15px 0;
    padding: 0 0 15px 0;
    list-style: none;
}

ul.team-detail li label {
    font-size: 13px;
}

ul.team-detail li h4, ul.team-detail li label {
    margin-bottom: 0;
}

ul.team-detail li ul.social-network {
    border: none;
    margin: 0;
    padding: 0;
}

ul.team-detail li ul.social-network li {
    border: none;
    margin: 0;
}

ul.team-detail li ul.social-network li i {
    margin: 0;
}


/* --- Pricing box --- */

.pricing-title {
    background: #fff;
    text-align: center;
    padding: 10px 0 10px 0;
}

.pricing-title h3 {
    font-weight: 600;
    margin-bottom: 0;
}

.pricing-offer {
    background: #fcfcfc;
    text-align: center;
    padding: 40px 0 40px 0;
    font-size: 18px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.pricing-box.special .pricing-offer {
    color: #fff;
}

.pricing-offer strong {
    font-size: 78px;
    line-height: 89px;
}

.pricing-offer sup {
    font-size: 28px;
}

.pricing-content {
    background: #fff;
    text-align: center;
    font-size: 14px;
}

.pricing-content strong {
    color: #353535;
}

.pricing-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pricing-content ul li {
    border-bottom: 1px solid #e9e9e9;
    list-style: none;
    padding: 15px 0 15px 0;
    margin: 0 0 0 0;
    color: #888;
}

.pricing-action {
    margin: 0;
    background: #fcfcfc;
    text-align: center;
    padding: 20px 0 30px 0;
}

.pricing-wrapp {
    margin: 0 auto;
    width: 100%;
    background: #fd0000;
}


/* --- pricing box alt 1 --- */

.pricing-box-alt {
    border: 1px solid #e6e6e6;
    background: #fcfcfc;
    position: relative;
    margin: 0 0 20px 0;
    padding: 0;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.pricing-box-alt .pricing-heading {
    background: #fcfcfc;
    text-align: center;
    padding: 40px 0 0px 0;
    display: block;
}

.pricing-box-alt.special .pricing-heading {
    background: #fcfcfc;
    text-align: center;
    padding: 40px 0 1px 0;
    border-bottom: none;
    display: block;
    color: #fff;
}

.pricing-box-alt.special .pricing-heading h3 {
    color: #fff;
}

.pricing-box-alt .pricing-heading h3 strong {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -1px;
}

.pricing-box-alt .pricing-heading h3 {
    font-size: 32px;
    font-weight: 300;
    letter-spacing: -1px;
}

.pricing-box-alt .pricing-terms {
    text-align: center;
    background: #333;
    display: block;
    overflow: hidden;
    padding: 30px 0 20px;
}

.pricing-box-alt .pricing-terms h6 {
    font-style: italic;
    margin-top: 10px;
    color: #fff;
    font-family: 'Noto Serif', serif;
}

.pricing-box-alt .icon .price-circled {
    margin: 10px 10px 10px 0;
    display: inline-block !important;
    text-align: center !important;
    color: #fff;
    width: 68px;
    height: 68px;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 68px;
    text-shadow: none;
    cursor: pointer;
    background-color: #888;
    border-radius: 64px;
    -moz-border-radius: 64px;
    -webkit-border-radius: 64px;
}

.pricing-box-alt .pricing-action {
    margin: 0;
    text-align: center;
    padding: 30px 0 30px 0;
}


/* -----------------------------------
14. Blog & article
----------------------------------- */

article {
    margin-bottom: 40px;
}

article .post-heading h3 {
    margin-bottom: 20px;
}

article .post-heading h3 a {
    font-weight: 700;
    color: #353535;
}

article .post-heading h3 a:hover {
    text-decoration: none;
}


/* --- post meta --- */

.post-meta {
    background: #fcfcfc;
    border: 1px solid #e6e6e6;
    border-top: none;
    text-align: center;
}

.post-meta .format {
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 0 10px;
}

.post-meta i {
    margin: 0;
}

.post-meta .date {
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 0 10px;
}

.post-meta .date span {
    text-align: center;
    color: #999;
    font-size: 12px;
    font-weight: 600;
}

.post-meta .comments {
    padding: 10px 0 10px;
}

.post-meta .comments a {
    color: #999;
    font-size: 12px;
    font-weight: 700;
}

.post-meta .comments a:hover {
    text-decoration: none;
}


/* --- post format --- */

.post-image {
    margin-bottom: 20px;
}

.post-quote {
    margin-bottom: 20px;
}

.post-video {
    margin-bottom: 20px;
}

.post-audio {
    margin-bottom: 20px;
}

.post-link {
    margin-bottom: 20px;
}

.post-slider {
    margin-bottom: 20px;
}

.post-quote blockquote {
    font-size: 16px;
    color: #999;
    font-family: 'Noto Serif', serif;
    font-style: italic;
    line-height: 1.6em;
}

.post-image img:hover {
    -webkit-box-shadow: 0 0 2px #979797;
    -moz-box-shadow: 0 0 2px #979797;
    box-shadow: 0 0 2px #979797;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

ul.meta-post {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.meta-post li {
    float: left;
    margin: 0 10px 0 0;
    padding: 0;
    list-style: none;
    border-right: 1px dotted #e9e9e9;
    padding-right: 10px;
}

ul.meta-post li i {
    float: left;
    font-size: 12px;
    margin: 2px 5px 0 0;
}

ul.meta-post li a {
    color: #999;
    float: left;
    font-size: 12px;
    font-weight: 600;
}


/* --- single post --- */

.bottom-article {
    overflow: hidden;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding: 10px 0 10px 0;
    margin-top: 20px;
}


/* --- author info --- */

.about-author {
    border: 1px solid #f5f5f5;
    padding: 20px;
    float: left;
    margin-bottom: 5px;
}

.about-author h5 {
    margin-bottom: 10px;
}


/* --- Comment --- */

.comment-area {
    float: left;
    width: 100%;
    margin-top: 30px;
}

.media-content {
    overflow: hidden;
    margin-left: 12px;
    border-bottom: 1px dotted #d5d5d5;
    padding-bottom: 20px;
}

.media-content h6 {
    font-size: 14px;
    font-weight: 700;
}

.media-content span {
    color: #a9a9a9;
    font-size: 14px;
    font-weight: 300;
}

.comment-area .thumbnail>img {
    display: block;
    width: 60px;
    height: 60px;
}

.filter-list {
    margin-top: 20px;
}


/* --- pagination --- */

#pagination a, #pagination span {
    display: block;
    float: left;
    margin: 0 7px 0 0;
    padding: 7px 10px 6px 10px;
    font-size: 12px;
    line-height: 12px;
    color: #888;
    font-weight: 600;
}

#pagination a:hover {
    color: #fff;
    text-decoration: none;
}

#pagination span.current {
    background: #333;
    color: #fff;
    font-weight: bold;
}


/* -----------------------------------
15. Aside, sidebar
----------------------------------- */

aside {
    position: relative;
    margin-bottom: 40px;
}

.sidebar-nav {
    float: left;
    width: 100%;
}

.right-sidebar {
    border-left: 1px solid #f5f5f5;
    padding: 0 0 0 30px;
    -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, .01);
    box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, .01);
}

.left-sidebar {
    border-right: 1px solid #f5f5f5;
    padding: 0 30px 0 0;
    -webkit-box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, .01);
    box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, .01);
}

.left-sidebar .widget h3, .left-sidebar .widget .widget-content {
    padding-right: 20px;
}

aside .widget {
    margin-bottom: 40px;
}

ul.cat, ul.recent, ul.folio-detail, ul.contact-info {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.cat li, ul.recent li, ul.folio-detail li, ul.contact-info li {
    margin: 0 0 15px 0;
    list-style: none;
}

ul.cat li a, ul.folio-detail li a {
    color: #656565;
}

ul.folio-detail li label {
    display: inline;
    cursor: default;
    color: #353535;
    font-weight: bold;
}

.widget ul.recent li {
    margin-bottom: 20px;
}

ul.recent li h6 {
    margin: 0 0 10px 0;
}

ul.recent li h6 a {
    color: #353535;
    font-size: 16px;
    font-weight: 600;
}

.widget ul.tags {
    list-style: none;
    margin: 0;
    margin-left: 0;
    padding-left: 0;
}

.widget ul.tags li {
    margin: 0 5px 15px 0;
    display: inline-block;
}

.widget ul.tags li a {
    background: #e6e6e6;
    color: #333;
    padding: 5px 10px;
}

.widget ul.tags li a:hover {
    text-decoration: none;
    color: #fff;
}


/* ===== Widgets ===== */


/* --- flickr --- */

.widget .flickr_badge {
    width: 100%;
}

.widget .flickr_badge img {
    margin: 0 9px 20px 0;
}

footer .widget .flickr_badge {
    width: 100%;
}

footer .widget .flickr_badge img {
    margin: 0 9px 20px 0;
}

.flickr_badge img {
    width: 50px;
    height: 50px;
    float: left;
    margin: 0 9px 20px 0;
}


/* --- latest tweet widget --- */

.twitter ul {
    margin-left: 0;
    list-style: none;
}

.twitter img {
    float: left;
    margin-right: 15px;
}

.twitter span.tweet_time {
    display: block;
    padding-bottom: 5px;
}

.twitter li {
    border-bottom: 1px dashed #efefef;
    padding-bottom: 20px;
    margin-bottom: 20px;
    list-style: none;
}

.twitter li:last-child {
    border: none;
}


/* --- Recent post widget --- */

.recent-post {
    margin: 20px 0 0 0;
    padding: 0;
    line-height: 18px;
}

.recent-post h5 a:hover {
    text-decoration: none;
}

.recent-post .text h5 a {
    color: #353535;
}

ul.time-post {
    float: left;
    width: 120px;
    padding: 0;
    list-style: none;
    margin: 0 20px 0 0;
    text-align: center;
    color: #0e0f0f;
}

ul.time-post li {
    float: none;
    background: #f8f8f8;
    list-style: none;
    margin: 0 0 2px 0;
    padding: 0;
}

ul.time-post li a img:hover {
    opacity: 0.8;
    /* For IE 5-7 */
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    /* For IE 8 8 */
    -MS-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

ul.time-post li.time {
    float: none;
    padding: 5px 0 5px 0;
    text-shadow: none;
}

.recent-post ul.time-post li.time {
    color: #121214;
}

.post-meta {
    margin-bottom: 20px;
}

.post-meta span {
    padding: 0 10px 0 10px;
    margin-bottom: 10px;
}

.post-meta span a {
    color: #919191;
}

.post-meta strong {
    font-weight: 200px;
    color: #c7c7c7;
}

.item .text {
    margin-left: 140px;
    margin-bottom: 20px;
}


/* -----------------------------------
16. Footer
----------------------------------- */

footer {
    padding: 50px 0 0 0;
    color: #f8f8f8;
    margin-top: auto;
}

footer a {
    color: #fff;
}

footer a:hover {
    color: #eee;
}

footer h1, footer h2, footer h3, footer h4, footer h5, footer h6 {
    color: #fff;
}

footer address {
    line-height: 1.6em;
}

footer h5 a:hover, footer a:hover {
    text-decoration: none;
}

ul.social-network {
    list-style: none;
    margin: 0;
}

ul.social-network li {
    display: inline;
    margin: 0 5px;
}

#sub-footer {
    text-shadow: none;
    color: #f5f5f5;
    padding: 0;
    padding-top: 30px;
    margin: 20px 0 0 0;
}

#sub-footer p {
    margin: 0;
    padding: 0;
}

#sub-footer span {
    color: #f5f5f5;
}

.copyright {
    text-align: left;
    font-size: 12px;
}

#sub-footer ul.social-network {
    float: right;
}


/* -----------------------------------
 Live preview purpose
----------------------------------- */


/* --- buttons --- */

.bs-docs-example {
    margin: 0 0 10px 0;
}

.demobtn a.btn {
    margin: 0 10px 10px 0;
}

section.demogrid .col-lg-1, section.demogrid .col-lg-2, section.demogrid .col-lg-3, section.demogrid .col-lg-4, section.demogrid .col-lg-5, section.demogrid .col-lg-6, section.demogrid .col-lg-7, section.demogrid .col-lg-8, section.demogrid .col-lg-9, section.demogrid .col-lg-10, section.demogrid .col-lg-11 {
    background: #f6f6f6;
    text-align: center;
    padding: 20px 0 20px;
}

section.demogrid .show-grid .row, section.demogrid .show-grid .row-fluid {
    margin-bottom: 0;
}

section.demogrid .show-grid .nest {
    background: #ddd;
    text-align: center;
    padding: 20px 0 20px;
}

section.demogrid .col-lg-12.nest {
    background: #f6f6f6;
    text-align: center;
    padding: 20px 0 0;
}

.demoinline p {
    display: inline;
    margin-right: 20px;
}


/* -----------------------------------
17. Divider, Line & Misc
----------------------------------- */

.solidline {
    border-top: 1px solid #f5f5f5;
    margin: 0 0 30px;
}

.dottedline {
    border-top: 1px dotted #f5f5f5;
    margin: 0 0 30px;
}

.dashedline {
    border-top: 1px dashed #f5f5f5;
    margin: 0 0 30px;
}

.blankline {
    height: 1px;
    margin: 0 0 30px;
}


/* scroll to top */

.scrollup {
    position: fixed;
    width: 32px;
    height: 32px;
    bottom: 0px;
    right: 20px;
    background: #222;
}

a.scrollup {
    outline: 0;
    text-align: center;
}

a.scrollup:hover, a.scrollup:active, a.scrollup:focus {
    opacity: 1;
    text-decoration: none;
}

a.scrollup i {
    margin-top: 10px;
    color: #fff;
}

a.scrollup i:hover {
    text-decoration: none;
}


/* -----------------------------------
18. Position & alignment
----------------------------------- */

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.aligncenter {
    text-align: center;
}

.aligncenter span {
    margin-left: 0;
}

.floatright {
    float: right;
}

.floatleft {
    float: left;
}

.floatnone {
    float: none;
}

.aligncenter {
    text-align: center;
}


/* --- Image allignment style --- */

img.pull-left, .align-left {
    float: left;
    margin: 0 15px 15px 0;
}

.widget img.pull-left {
    float: left;
    margin: 0 15px 15px 0;
}

img.pull-right, .align-right {
    float: right;
    margin: 0 0 15px 15px;
}

article img.pull-left, article .align-left {
    float: left;
    margin: 5px 15px 15px 0;
}

article img.pull-right, article .align-right {
    float: right;
    margin: 5px 0 15px 15px;
}


/* -----------------------------------
20. Spacer, margin
----------------------------------- */

.clear-marginbot {
    margin-bottom: 0;
}

.marginbot10 {
    margin-bottom: 10px;
}

.marginbot20 {
    margin-bottom: 20px;
}

.marginbot30 {
    margin-bottom: 30px;
}

.marginbot40 {
    margin-bottom: 40px;
}

.clear-margintop {
    margin-top: 0;
}

.margintop10 {
    margin-top: 10px;
}

.margintop20 {
    margin-top: 20px;
}

.margintop30 {
    margin-top: 30px;
}

.margintop40 {
    margin-top: 40px;
}


/* -----------------------------------
21. Media queries
----------------------------------- */

@media (min-width: 768px) and (max-width: 979px) {
    a.detail {
        background: none;
        width: 100%;
    }
    footer .widget form input#appendedInputButton {
        display: block;
        width: 91%;
        -webkit-border-radius: 4px 4px 4px 4px;
        -moz-border-radius: 4px 4px 4px 4px;
        border-radius: 4px 4px 4px 4px;
    }
    footer .widget form .input-append .btn {
        display: block;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin-top: 10px;
    }
    ul.related-folio li {
        width: 156px;
        margin: 0 20px 0 0;
    }
}

@media (max-width: 767px) {
    body {
        padding-right: 0;
        padding-left: 0;
    }
    .navbar-brand {
        margin-top: 10px;
        border-bottom: none;
    }
    .navbar-header {
        margin-top: 20px;
        border-bottom: none;
    }
    .navbar-nav {
        border-top: none;
        float: none;
        width: 100%;
    }
    header .navbar-nav>li {
        padding-bottom: 0;
        padding-top: 0;
    }
    header .nav li .dropdown-menu {
        margin-top: 0;
    }
    .dropdown-menu {
        position: absolute;
        top: 0;
        left: 40px;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 160px;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 13px;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #f5f5f5;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 0;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }
    .navbar-collapse.collapse {
        border: none;
        overflow: hidden;
    }
    .box {
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 20px;
    }
    #featured .flexslider .slide-caption {
        width: 90%;
        padding: 2%;
        position: absolute;
        left: 0;
        bottom: -40px;
    }
    #inner-headline .breadcrumb {
        float: left;
        clear: both;
        width: 100%;
    }
    .breadcrumb>li {
        font-size: 13px;
    }
    ul.portfolio li article a i.icon-48 {
        width: 20px;
        height: 20px;
        font-size: 16px;
        line-height: 20px;
    }
    .left-sidebar {
        border-right: none;
        padding: 0 0 0 0;
        border-bottom: 1px dotted #e6e6e6;
        padding-bottom: 10px;
        margin-bottom: 40px;
    }
    .right-sidebar {
        margin-top: 30px;
        border-left: none;
        padding: 0 0 0 0;
    }
    footer .col-lg-1, footer .col-lg-2, footer .col-lg-3, footer .col-lg-4, footer .col-lg-5, footer .col-lg-6, footer .col-lg-7, footer .col-lg-8, footer .col-lg-9, footer .col-lg-10, footer .col-lg-11, footer .col-lg-12 {
        margin-bottom: 20px;
    }
    #sub-footer ul.social-network {
        float: left;
    }
    [class*="span"] {
        margin-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .bottom-article a.pull-right {
        float: left;
        margin-top: 20px;
    }
    .search {
        float: left;
    }
    .flexslider .flex-caption {
        display: none;
    }
    .cta-text {
        margin: 0 auto;
        text-align: center;
    }
    ul.portfolio li article a i {
        width: 20px;
        height: 20px;
        font-size: 14px;
    }
}

@media (max-width: 360px) {}


/* ===================
 Components
==================== */


/* ------ OfferWizard ------ */

#offer_wizard {}

#offer_wizard ul{
    list-style: none;
}
#offer_wizard ul li .title{
    font-size: 25px;
}

#offer_wizard ul li .buttons{
    margin: 20px 35px;
}


#offer_wizard .main-blocks {
    font-size: 25px;
    font-weight: bold;
}

#offer_wizard .main-blocks .block {
    padding: 50px;
    min-height: 150px;
    margin: 20px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

#offer_wizard .main-blocks .block:hover {
    box-shadow: 0 14px 20px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

#offer_wizard .main-blocks .block span {
    color: #30ABCA;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 13px 13px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
}

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
    margin-right: .5em;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: .8em;
    line-height: 0;
    top: 50%;
    left: 20%;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .2s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}

.f-big{
    font-size: 25px;
}

.questions label{
    font-size: 1.5em;
}

.form-group.required label h4:after {
    color: #d00;
    content: "*";
    position: absolute;
    margin-left: 8px;
    top:7px;
}

.required-star:after{
    color: #d00;
    content: "*";
    margin: 0px 3px;
}

.middle-box {
    max-width: 400px;
    z-index: 100;
    margin: 0 auto;
    padding-top: 40px;
  }
  .lockscreen.middle-box {
    width: 200px;
    padding-top: 110px;
  }
  .loginscreen.middle-box {
    width: 300px;
  }
  .loginColumns {
    max-width: 800px;
    margin: 0 auto;
    padding: 100px 20px 20px 20px;
  }
  .passwordBox {
    max-width: 460px;
    margin: 0 auto;
    padding: 100px 20px 20px 20px;
  }
  .logo-name {
    color: #e6e6e6;
    font-size: 180px;
    font-weight: 800;
    letter-spacing: -10px;
    margin-bottom: 0;
  }
  .middle-box h1 {
    font-size: 170px;
  }
  .wrapper .middle-box {
    margin-top: 140px;
  }
  .lock-word {
    z-index: 10;
    position: absolute;
    top: 110px;
    left: 50%;
    margin-left: -470px;
  }
  .lock-word span {
    font-size: 100px;
    font-weight: 600;
    color: #e9e9e9;
    display: inline-block;
  }
  .lock-word .first-word {
    margin-right: 160px;
  }

#content{
    .fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
        width: 100% !important;
    }
}

.blog {
    .title{
        small{
            font-size: 40%;
        }
    }
    #content{
        padding-top: 0;
    }
    .card{
        background-color: #FFFFFF;
        padding:0;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius:4px;
        box-shadow: 0 10px 11px -10px rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
        margin-top: 20px;
        transition: .2s box-shadow;
    }
    .card:hover{
        box-shadow: 0 10px 22px 2px rgba(0, 0, 0, 0.12), 0 6px 29px 5px rgba(0, 0, 0, 0.10), 0 8px 4px -5px rgba(0, 0, 0, 0.2);
        color:black;
    }
    .card img{
        width:100%!important;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .card h4{
        margin:20px;
    }
    .card p{
        margin:20px;
        opacity: 0.65;
    }
    .card .btn{
        margin-bottom: 20px;
        margin-left: 20px;
    }
}

